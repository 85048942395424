// Use This URL for testing
const URL = "transcal.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "Indian Bank",
  "Bank A/c. No.": "446626407",
  "IFSC Code": "IDIB000M010",
  "Bank Branch": "Malleshwaram",
};
export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Department","Request Form","Attendance","MarkAttendance","CoordinatesAddress","Client Complaints","Client Feedback","Settings","Datasheet Template","Discipline","WCR Report","Supplier Invoice","Department Analytics","OfflineMode","Training Resources"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];
