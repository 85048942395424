import { getCustomColumnCount } from "../../helper";
import processDatasheetTemplate from "../processDatasheetTemplate";
import { prepareDatasheetReadings } from "../utils";

const checkMasters = (
  staticReadingRows,
  certificateStaticTables,
  staticTables
) => {
  if (
    staticReadingRows
      .map((row) => {
        if (
          !String(row[2])?.includes("_unit_") &&
          !String(row[2])?.includes("_rh_")
        ) {
          let masters =
            row[
              2 +
                getCustomColumnCount(
                  row[0],
                  certificateStaticTables,
                  staticTables,
                  "static"
                ) +
                1
            ];
          masters =
            masters !== undefined && masters !== null && masters !== ""
              ? `${masters}`
                  .split(",")
                  .map((e) => e.trim())
                  .filter((e) => e.length > 0)
              : [];
          if (masters.length === 0) return true;
        }
        return false;
      })
      .filter((e) => e).length
  ) {
    if (
      window.confirm("Masters is/are not added for rows? Do you want to save?")
    )
      return true;
    return false;
  }
  return true;
};

export async function processDatasheet(
  staticTables,
  staticReadingRows,
  datasheetObservedReadingFallbackValue,
  certificateStaticTables,
  datasheetDetails,
  standardRanges,
  referenceData,
  ) {
  try {
    console.log("validating datasheet readings");
    if (
      !checkMasters(staticReadingRows, certificateStaticTables, staticTables)
    ) {
      return false;
    }
    console.log("processing datasheet readings");
    // prepare readings
    let config = staticTables.map((table, tableIndex) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration);
      return {
        tableId: table.id,
        index: tableIndex,
        formulas: defaultConfiguration.formulas,
        customPrecision: JSON.parse(table.customPrecision),
      };
    });

    let updatedReadingRows = await prepareDatasheetReadings({
      readings: staticReadingRows,
      datasheetObservedReadingFallbackValue: datasheetObservedReadingFallbackValue,
      uncertaintyIndex: -3, // 3rd from last
      config: config,
      precisionCount: 7, // TODO:  take it from datasheet config reference
      datasheet: datasheetDetails,
      standardRanges: standardRanges,
      referenceData: referenceData,
      operations: [""],
    });
    updatedReadingRows = await processDatasheetTemplate(
      updatedReadingRows,
      datasheetDetails?.instrumentId,
      staticTables,
      datasheetDetails?.id,
    );

    // TODO: this process is getting called twice, which shouldn't. really need to optimize
    updatedReadingRows = prepareDatasheetReadings({
      readings: updatedReadingRows,
      datasheetObservedReadingFallbackValue: datasheetObservedReadingFallbackValue,
      uncertaintyIndex: -3, // 3rd from last
      config: config,
      precisionCount: 7, // TODO:  take it from datasheet config reference
      datasheet: datasheetDetails,
      standardRanges: standardRanges,
      referenceData: referenceData,
      operations: ["setPrecision"],
    });

    console.log("datasheet readings process completed.");
    return updatedReadingRows;
    
  } catch (error) {
    console.error("[ERROR] Error occured while processing datasheet readings, error: ", error);
    alert("Failed while processing datasheet data, please try again.");

    return false;
  }
}
