import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { useLocation } from "react-router";

const ExcelDownload = ({ finalData, notNeededColumn }) => {
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const location = useLocation();
  const routeSegments = location.pathname.split("/");
  const selectedRouteInUpperCase = (routeSegments[1] === "master" ? routeSegments[2] : routeSegments[1])
  .replace(/([A-Z])/g, " $1")
  .replace(/^./, (str) => str.toUpperCase());

  const convertToExcel = () => {
    const dataWithoutNotNeededKeys =
      notNeededColumn.length > 0
        ? finalData.map((item) => {
            const filteredItem = {};
            for (const key in item) {
              if (item.hasOwnProperty(key) && !notNeededColumn.includes(key)) {
                filteredItem[key] = shouldConvertToDate(key)
                  ? item[key] ? new Date(item[key]).toLocaleString() : ""
                  : item[key];
              }
            }
            return filteredItem;
          })
        : finalData;

    const worksheet = XLSX.utils.json_to_sheet(dataWithoutNotNeededKeys);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `${selectedRouteInUpperCase || "data"}.xlsx`);
  };

  function shouldConvertToDate(key) {
    // Add keys that should be converted to a localized string here
    const dateKeys = ["checkinDatetime", "checkoutDatetime", "lastModified","outwardDate","expectedReturnDate","returnDate","siteInwardDate"];
    return dateKeys.includes(key);
  }

  return (
    <div>
      <Button
        id="stdMastersEQPList_convertexcel"
        variant="contained"
        size="small"
        disabled={!(editAccess?.includes(4) || editAccess?.includes(0))}
        style={{ marginLeft: "10px", height: "40px", fontSize: "12px" }}
        onClick={convertToExcel}
      >
        Export Excel
      </Button>
    </div>
  );
};

export default ExcelDownload;
