import React, { useEffect, useRef } from 'react';

const CustomPDFViewer = ({ url }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadPdf = async () => {
      if (!url) return;

      try {

        const loadingTask = window.pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);

        const viewport = page.getViewport({ scale: 1.3 });
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;

        // Disable right-click on the canvas
        canvas.addEventListener('contextmenu', (e) => e.preventDefault());
      } catch (error) {
        console.error('Error loading or rendering PDF:', error);
      }
    };

    loadPdf();

    // Cleanup event listener on component unmount
    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('contextmenu', (e) => e.preventDefault());
      }
    };
  }, [url]);

  return (
    <div
      style={{
        position: 'relative',
        height: '500px',
        overflow: 'auto',
      }}
    >
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CustomPDFViewer;
