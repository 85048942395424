// src/components/ResourceModel.js

import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import CustomPDFViewer from "./CustomPDFViewer";

const extractYouTubeId = (url) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
  );
  return match ? match[1] : null;
};

export default function ResourceModel({ open, onClose, resourceUrl }) {
  const isYouTube =
    resourceUrl &&
    (resourceUrl?.includes("youtube.com/watch") ||
      resourceUrl?.includes("youtu.be/"));
  const isPDF = resourceUrl && resourceUrl?.endsWith(".pdf");
  const isImage =
    resourceUrl &&
    (resourceUrl?.endsWith(".jpg") ||
      resourceUrl?.endsWith(".jpeg") ||
      resourceUrl?.endsWith(".png"));

  const videoId = isYouTube ? extractYouTubeId(resourceUrl) : null;
  const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : "";

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Button
        variant="contained"
        size="small"
        color="error"
        sx={{
          position: "absolute",
          right: 25,
          top: 3,
          height: "25px",
          minWidth: "40px",
        }}
        onClick={onClose}
      >
        X
      </Button>
      <br />
      <DialogContent
        style={{ margin: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        {resourceUrl ? (
          isYouTube ? (
            <iframe
              title="Video"
              width="100%"
              height="450px"
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : isPDF ? (
            <CustomPDFViewer url={resourceUrl} />
          ) : isImage ? (
            <img
              src={resourceUrl}
              alt="Resource"
              style={{ width: "100%", height: "500px" }}
              onContextMenu={(e) => e.preventDefault()}
            />
          ) : (
            <Typography variant="h6">Unsupported resource type</Typography>
          )
        ) : (
          <Typography variant="h6">No resource URL provided</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
