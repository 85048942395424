import React, { useEffect, useState } from "react";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import { Button, Tooltip } from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";

export const SopViewFile = ({ instrumentId }) => {
  const [loading, setLoading] = useState(false);

  function getInstrumentSopFile() {
    setLoading(true);
    axiosWithToken
      .get(`${BASE_URL}instruments/${instrumentId}`)
      .then((response) => {
        if (response.data[0]?.sopUpload) {
          window.open(response.data[0]?.sopUpload, "_blank");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Tooltip title="View SOP" placement="top-start">
      <Button onClick={getInstrumentSopFile} disabled={loading}>
        <MemoryIcon />
      </Button>
    </Tooltip>
  );
};

export const TrainingAndMasterViewFile = ({ calibratedbyID }) => {
  const [loading, setLoading] = useState(false);

  function getCalibratedUserTraiingCertiifcate() {
    setLoading(true);
    axiosWithToken
      .get(`${BASE_URL}users/${calibratedbyID}`)
      .then((response) => {
        if (response.data[0]?.trainingCertificate) {
          window.open(response.data[0]?.trainingCertificate, "_blank");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Tooltip title="View Training Certificate" placement="top-start">
      <Button onClick={getCalibratedUserTraiingCertiifcate} disabled={loading}>
        <ModelTrainingIcon />
      </Button>
    </Tooltip>
  );
};
